<template lang="html">
	<div class="view">
		<step-progress :sector="sector" :steps="sectorSteps" v-model="step" />
		<component :is="sectorStep" :sector="sector" @validate="validateStep" />
		<p class="columns">
			<span class="column">
				<button
					v-if="hasPrevStep"
					@click="prevStep"
					:disabled="!isValidStep"
					class="btn">
					{{ $t('global.button.prev') }}
				</button>
				<button
					v-if="hasNextStep"
					@click="nextStep"
					:disabled="!isValidStep"
					class="btn">
					{{ $t('global.button.next') }}
				</button>
			</span>
			<span class="column column--right">
				<confirm-dialog
					:question="$t('dialog.unsave_exit')"
					:acceptance="$t('global.button.exit')"
					@accept="exit">
					<button class="btn btn--outline">
						{{ $t('global.button.exit') }}
					</button>
				</confirm-dialog>
			</span>
		</p>
  </div>
</template>

<script>
import StepProgress from '@/components/StepProgress.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default {
	name: 'CalculatorView',
	components: { StepProgress, ConfirmDialog },
	props: { sector: { type: String, required: true } },
	data() {
		return {
			step: 0,
			isValidStep: false,
		};
	},
	computed: {
		hasPrevStep() { return this.step > 0; },
		hasNextStep() { return this.step < this.sectorSteps.length - 1; },
		sectorSteps() {
			return this.sector === 'residential'
				? ['details', 'a1_2', 'a3', 'results']
				: ['details', 'a1', 'a2', 'a3', 'results'];
		},
		sectorStep() {
			const step = capitalize(this.sectorSteps[this.step]);
			const path = step !== 'Results' ? `${this.sector}/Step${step}` : 'ResultsView';
			return () => import(`./${path}.vue`);
		},
	},
	methods: {
		validateStep(valid) { this.isValidStep = valid; },
		prevStep() { if (this.hasPrevStep) this.step -= 1; },
		nextStep() { if (this.hasNextStep) this.step += 1; },
		exit() { this.$emit('exit'); },
	},
};
</script>
