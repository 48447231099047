import Locale from '@/locales/ca/index';
import { shortDate, toTones } from '@/utils';

const abasts = {
	a1: ['fuels', 'mobils'],
	a2: ['energy'],
	a3: ['fuels', 'energy', 'workers', 'spectators', 'external', 'products', 'waste', 'water'],
};

export default function createLayout(reports) {
	// CSV HEADER
	const header = [
		Locale.report.detail.name,
		Locale.report.detail.owner,
		Locale.report.detail.sector,
		Locale.report.detail.type,
		Locale.report.detail.date,
		Locale.report.detail.status,
		...Object.entries(abasts).flatMap(([abast, tables]) => (
			tables.map((table) => `${abast}.${Locale.event[abast][table].title}`)
		)),
		'Total',
		Locale.report.detail.compensated,
	];

	// CSV BODY
	const csv = reports.map((report) => {
		const { name, type, status, owner, author, compensation, content } = report;
		const { dates, year } = content.details;
		const groupedEmissions = Object.entries(abasts)
			.flatMap(([abast, tables]) => tables.map((table) => {
				if (!content.emissions[abast]) return 0;
				if (!content.emissions[abast][table]) return 0;
				const total = content.emissions[abast][table]
					.reduce((acc, { emissions }) => acc + emissions, 0);
				return toTones(total);
			}));
		return [
			`"${name}"`,
			`"${(owner && owner.name) || author.name}"`,
			Locale.report.type[type],
			content.details.type.label,
			(dates && shortDate(dates[0])) || year,
			Locale.report.status[status],
			...groupedEmissions,
			Math.round(groupedEmissions.reduce((acc, value) => acc + value, 0) * 100) / 100,
			(compensation && compensation.amount) || 0,
		].join(',');
	}).join('\n');

	return [header.join(','), csv].join('\n');
}
