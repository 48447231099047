export default {

	title: 'Esdeveniments',
	tagline: 'Calcula les emissions generades durant un esdeveniment realitzat per la teva empresa. Utilitza el CERTIFICAT DIGITAL CORPORATIU.',

	details: {
		name: 'Detalls',
		title: 'Detalls',
		placeholder: {
			name: 'Nom',
			organizer: 'Organitzador',
			dates: 'Dates',
			location: 'Parròquia',
			type: 'Tipus',
			partakers: 'Participants',
			spectators: 'Espectadors',
			observations: 'Observacions',
		},
	},

	a1: {
		name: 'Emissions directes',
		title: 'Emissions directes de CO₂',
		desc: 'Emissions associades al consum de combustibles fòssils de la mateixa organització i les associades al transport propi.',

		fuels: {
			title: 'Emissions fixes',
			desc: "Introduïu la quantitat dels diferents combustibles consumits per la vostra organització en l'esdeveniment analitzat, a excepció dels relacionats amb el transport (les emissions relacionades amb el transport s'han d'incloure a l'apartat 2 Emissions directes > Emissions mòbils - Transport propi, i a l'apartat 4 Altres emissions > Emissions mòbils).",
			example: 'p.ex. Calefacció Sala 1',
		},
		mobils: {
			title: 'Emissions mòbils',
			desc: "Introduïu la informació relativa als vehicles propis utilitzats en l'esdeveniment analitzat. Si coneixeu el consum de combustible associat a cada vehicle, utilitzeu el mètode 1. Com a alternativa, podeu utilitzar el mètode 2 si únicament coneixeu els quilòmetres realitzats.",
		},
	},

	a2: {
		name: 'Emissions indirectes',
		title: 'Emissions indirectes de CO₂',
		desc: "Emissions derivades de l'ús d'electricitat i calor.",
		energy: {
			title: 'Emissions fixes',
			desc: "Introduïu l'origen i la quantitat d'electricitat o calor utilitzada en les diferents activitats o emplaçaments.",
			example: 'p.ex. consum elèctric oficines',
		},
	},

	a3: {
		name: 'Altres emissions',
		title: 'Altres emissions de CO₂',
		desc: "Emissions indirectes derivades de subcontractacions, l'adquisició de materials, compres externalitzades i activitats relacionades amb el transport realitzades amb vehicles que no són propietat de l'organització.",
		fuels: {
			title: 'Combustibles fòssils',
			desc: "Introduïu la quantitat de combustibles fòssils utilitzats en l'esdeveniment de forma indirecta, a través de tercers.",
			example: 'p.ex. Grup electrogen subcontractat',
		},
		energy: {
			title: 'Electricitat i calor',
			desc: "Introduïu la quantitat de combustibles fòssils utilitzats en l'esdeveniment de forma indirecta, a través de tercers.",
			example: 'p.ex. Consum elèctric paradistes',
		},
		workers: {
			title: 'Mobilitat de treballadors',
			desc: "Introduïu la informació relativa als viatges comercials vinculats a l'esdeveniment realitzats pels treballadors amb vehicles que no pertanyen a l'organització.",
			example: 'p.ex. Lloguer vehicle',
		},
		spectators: {
			title: 'Mobilitat de participants i espectadors',
			desc: "Introduïu els viatges associats a la mobilitat de participants i espectadors de l'esdeveniment.",
			example: "p.ex. Espectadors de l'Alt Urgell",
		},
		external: {
			title: 'Transport extern',
			desc: "Introduïu el transport vinculat a l'esdeveniment però contractat de forma externa.",
			example: 'p.ex. Muntatge de stands',
		},
		products: {
			title: 'Productes i serveis',
			desc: "Introduïu a continuació la quantitat de productes i serveis utilitzats en l'esdeveniment.",
			example: 'p.ex. Allotjament del jurat',
		},
		waste: {
			title: 'Residus',
			desc: "Introduïu la quantitat i tipus de residus generats en les diferents activitats de l'esdeveniment analitzat.",
			example: "p.ex. Cerimònia d'obertura",
		},
		water: {
			title: 'Aigua',
			desc: "Introduïu la quantitat d'aigua utilitzada en les diferents activitats de l'esdeveniment analitzat.",
			example: 'p.ex. Manteniment gespa de jardins',
		},
	},

	// Needed for StepProgress
	results: { name: 'Resultats' },

};
