export default {

	require: {
		fuel: 'Selecciona abans un combustible',
		method: 'Selecciona abans un mètode',
		vehicle: 'Selecciona abans un vehicle',
		motorization: 'Selecciona abans una motorització',
		energy_source: 'Selecciona abans una font energètica',
		category: 'Selecciona abans una categoria',
		product: 'Selecciona abans un producte',
		waste: 'Selecciona abans un residu',
		source: 'Selecciona abans un origen',
		unit: 'Introdueix abans una unitat',
		animal: 'Selecciona abans un animal',
		gas: 'Selecciona abans un gas',
		diet: 'Selecciona abans una dieta',
	},

	unit_in: 'Quantitat en {unit}',

	qty_vary: "Les unitats d'aquest camp varien en funció del mètode, vehicle i motorització utilitzats",
	km_vs_hour: "Depenent de la tipologia de vehicle, aquest camp s'ha d'especificar en hores d'operació en comptes de kilòmetres",
	vehicle_vs_people: "Depenent de la tipologia de vehicle, aquest camp s'ha d'especificar en nombre de vehicles o en persones traslladades",
	all_products: "Assegura't d'incloure la totalitat dels productes utilitzats. p.ex. Total DINA4 = nº publicacions x pàgines/publicació",
	waste_bags: 'Es considera com a unitat una bossa de 30 litres',

};
