export default {

	title: 'Organitzacions',
	tagline: 'Calcula les emissions generades per la teva organització. Utilitza el CERTIFICAT DIGITAL CORPORATIU',

	details: {
		name: 'Detalls',
		title: 'Detalls',
		placeholder: {
			name: 'Nom',
			year: 'Any',
			location: 'Parròquia',
			type: 'Tipus',
			sector: 'Sector',
			cesi_code: 'Codi(s) CESI',
			workers: 'Treballadors',
			observations: 'Observacions',
		},

		cesi_find_info: 'Més informació sobre com trobar el codi CESI del(s) teu(s) edifici(s) en {0}.',
		cesi_find_link: 'aquest document',
	},

	a1: {
		name: 'Emissions directes',
		title: 'Emissions directes de CO₂',
		desc: 'Emissions associades al consum de combustibles fòssils de la mateixa organització i les associades al transport propi.',

		fuels: {
			title: 'Emissions fixes',
			desc: "Introduïu la quantitat dels diferents combustibles consumits per la vostra organització, a excepció dels relacionats amb el transport (les emissions relacionades amb el transport s'han d'incloure a l'apartat 2 Emissions directes > Emissions mòbils - Transport propi, i a l'apartat 4 Altres emissions > Emissions mòbils).",
			example: 'p.ex. Calefacció oficines',
		},
		mobils: {
			title: 'Emissions mòbils',
			desc: 'Introduïu la informació relativa als vehicles propis utilitzats per la vostra organització. Si coneixeu el consum de combustible associat a cada vehicle, utilitzeu el mètode 1. Com a alternativa, podeu utilitzar el mètode 2 si únicament coneixeu els quilòmetres realitzats.',
		},
		gases: {
			title: 'Emissions fugitives',
			desc: "Introduïu la quantitat de gas fluorat utilitzat per la vostra organització. Per determinar la càrrega de gas fluorat (en kg) a partir de la qual es calculen les emissions potencials de gasos d'efecte d'hivernacle, es pot recórrer a: (a) etiqueta informativa de l'equip, (b) manual o especificacions tècniques del fabricant, proveïdor o empresa de serveis de l’equip o (c) registres de l'equip. {0}",
			bopa_link: 'Decret del 2-10-2013 d’aprovació del Reglament de modificació del Reglament sobre les substàncies que esgoten la capa d’ozó i sobre determinats gasos fluorats d’efecte hivernacle',
			example: 'p.ex. Equip de refrigeració',
		},
		livestock: {
			title: 'Bestiar',
			desc: "Introduïu la informació relativa als caps de bestiar dels quals disposa la vostra organització. El factor d'emissions establert per aquesta calculadora considera les emissions de metà relatives a la fermentació entèrica i a la gestió de fems.",
			example: 'p.ex. Ramat boví Ordino',
		},
	},

	a2: {
		name: 'Emissions indirectes',
		title: 'Emissions indirectes de CO₂',
		desc: "Emissions derivades de l'ús d'electricitat i calor.",
		energy: {
			title: 'Emissions fixes',
			desc: "Introduïu l'origen i la quantitat d'electricitat o calor utilitzada en les diferents activitats o emplaçaments de la vostra organització.",
			example: 'p.ex. Consum elèctric oficina',
		},
	},

	a3: {
		name: 'Altres emissions',
		title: 'Altres emissions de CO₂',
		desc: "Emissions indirectes derivades de subcontractacions, l'adquisició de materials, compres externalitzades i activitats relacionades amb el transport realitzades amb vehicles que no són propietat de l'organització.",
		fuels: {
			title: 'Combustibles fòssils',
			desc: "Introduïu la quantitat de combustibles fòssils utilitzats per l'organització de forma indirecta, a través de tercers.",
			example: 'p.ex. Grup electrogen subcontractat',
		},
		energy: {
			title: 'Electricitat i calor',
			desc: "Introduïu la quantitat de combustibles fòssils utilitzats per l'organització de forma indirecta, a través de tercers.",
			example: 'p.ex. Consum elèctric magatzem',
		},
		gases: {
			title: 'Emissions fugitives',
			desc: "Introduïu la quantitat de gas fluorat utilitzat per organitzacions subcontractades. Per determinar la càrrega de gas fluorat (en kg) a partir de la qual es calculen les emissions potencials de gasos d'efecte d'hivernacle, es pot recórrer a: (a) etiqueta informativa de l'equip, (b) manual o especificacions tècniques del fabricant, proveïdor o empresa de serveis de l’equip o (c) registres de l'equip. {0}",
			bopa_link: 'Decret del 2-10-2013 d’aprovació del Reglament de modificació del Reglament sobre les substàncies que esgoten la capa d’ozó i sobre determinats gasos fluorats d’efecte hivernacle',
			example: 'p.ex. Equip de refrigeració subcontractat',
		},
		workers: {
			title: 'Mobilitat de treballadors',
			desc: "Introduïu la informació relativa als viatges comercials realitzats pels treballadors amb vehicles que no pertanyen a l'organització.",
			example: 'p.ex. Lloguer vehicle',
		},
		external: {
			title: 'Transport extern',
			desc: "Introduïu el transport vinculat a l'organització però contractat de forma externa.",
			example: 'p.ex. Repartiment material',
		},
		materials: {
			title: 'Productes i matèries primeres',
			desc: "Introduïu els productes i matèries primeres utilitzats en la vostra organització no contemplats en apartats anteriors. Si no coneixeu el factor d'emissió podeu consultar la {0}.",
			ipcc_db: "base de dades de l'IPCC",
			example: 'p.ex. Ciment',
			example_unit: 'p.ex. m3',
		},
		waste: {
			title: 'Residus',
			desc: "Introduïu la quantitat i tipus de residus generats en les diferents activitats de l'organització.",
			example: 'p.ex. Escombraries oficina',
		},
		water: {
			title: 'Aigua',
			desc: "Introduïu la quantitat d'aigua utilitzada en les diferents activitats de l'organització.",
			example: 'p.ex. Consum aigua oficines',
		},
	},

	// Needed for StepProgress
	results: { name: 'Resultats' },

};
