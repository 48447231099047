export default {

	detail: {
		name: 'Nom',
		owner: 'Propietari',
		author: 'Autor',
		status: 'Estat',
		created: 'Creat',
		modified: 'Modificat',
		sector: 'Sector',
		type: 'Tipus',
		date: 'Data',
		compensated: 'Compensat',
		empty: 'No hi ha informes disponibles',
	},

	group: {
		energy: 'Energia',
		transport: 'Transport',
		waste: 'Residus',
		products: 'Productes',
		water: 'Aigua',
		materials: 'Productes',
		gases: 'Gasos fluorats',
		diet: 'Dieta',
	},

	type: {
		DOM: 'Domèstic',
		EVT: 'Esdeveniment',
		ORG: 'Organització',
	},

	status: {
		DFT: 'Esborrany',
		VLD: 'Validat',
		CMP: 'Compensat',
		CRT: 'Certificat',
	},

	action: {
		view: 'Veure',
		edit: 'Editar',
		download_report: 'Descarregar informe',
		download_cert: 'Descarregar certificat',
		validate: 'Validar',
		compensate: 'Compensar',
		certify: 'Certificar',
		duplicate: 'Duplicar',
		transfer: 'Tranferir',
		downgrade: 'Degradar',
		delete: 'Eliminar',
	},

	table: {
		concept: 'Concepte',
		energy_source: 'Font energètica',
		fuel: 'Combustible',
		consumption: 'Consum',
		plate: 'Matrícula',
		method: 'Mètode',
		vehicle: 'Vehicle',
		bags: 'bosses',
		motorization: 'Motorització',
		distance: 'Distància',
		people: 'Persones',
		group: 'Categoria',
		product: 'Producte',
		waste: 'Residu',
		qty: 'Quantitat',
		source: 'Origen',
		emissions: 'Emissions',
		unit: 'unitat',
		emission_factor: "Factor d'emissions",
		gas: 'Gas',
		animal: 'Animal',
		diet: 'Dieta',
	},

	comparative: {
		flightEarth: 'voltes al món en avió',
		cow: 'vaques en un any',
		car: 'cotxes en un any',
	},
};
