<template lang="html">
	<confirm-dialog
		v-if="allow.includes(currentStatus.next)"
		:data-tooltip="$t(`report.action.${currentStatus.action}`)"
		:acceptance="$t(`report.action.${currentStatus.action}`)"
		:refusal="$t('global.button.cancel')"
		:is-valid="isValid"
		@accept="handleUpgrade(currentStatus.fn)">
		<template v-slot:question>
			<p>{{ $t('dialog.report_upgrade') }}</p>
			<div v-if="currentStatus.next === 'CMP'">
				<i18n path="dialog.report_compensation.details" tag="p" class="note">
					<a place="link" href="https://offset.climateneutralnow.org" target="blank">
						{{ $t('dialog.report_compensation.link') }}
					</a>
				</i18n>
				<p>
					{{ $t('dialog.report_compensation.amount') }}
					<input
						type="number"
						min="0"
						step="0.01"
						v-model.number="compensation.amount"
						placeholder="tCO₂" />
				</p>
				<file-uploader
					accept="application/pdf"
					:max-file-size="2000"
					:placeholder="$t('dialog.report_compensation.file_description')"
					@load="file => compensation.certificate = file"
					@fail="error => flashMessage('error', error)"
					class="droparea" />
			</div>
		</template>
		<i :class="`mdi mdi-${currentStatus.icon} icon-btn`" />
	</confirm-dialog>
</template>

<script>
import api from '@/api';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FileUploader from '@/components/FileUploader.vue';

const UPGRADES = {
	DFT: { next: 'VLD', action: 'validate', icon: 'thumb-up', fn: 'upgradeReport' },
	VLD: { next: 'CMP', action: 'compensate', icon: 'cash-usd', fn: 'compensateReport' },
	CMP: { next: 'CRT', action: 'certify', icon: 'certificate', fn: 'upgradeReport' },
	CRT: { next: null },
};

export default {
	name: 'ReportUpgrader',
	components: { ConfirmDialog, FileUploader },
	props: {
		allow: { type: Array, default: () => [] },
		report: { type: Object, required: true },
	},
	data() {
		return {
			compensation: {
				certificate: undefined,
				amount: undefined,
			},
		};
	},
	computed: {
		currentStatus() { return UPGRADES[this.report.status]; },
		isValid() {
			switch (this.currentStatus.next) {
				case 'CMP': return !!this.compensation.certificate && !!this.compensation.amount;
				default: return true;
			}
		},
	},
	methods: {
		handleUpgrade(fn) { this[fn](); },
		upgradeReport() {
			api.put(`/reports/${this.report.id}`, { status: this.currentStatus.next })
				.then(({ data }) => this.$emit('upgrade', data))
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		compensateReport() {
			const formData = new FormData();
			formData.append('report_id', this.report.id);
			formData.append('amount', this.compensation.amount);
			formData.append('file', this.compensation.certificate);
			api.post('/compensations', formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
				.then(({ data }) => {
					const { report } = data;
					report.compensation = data;
					this.$emit('upgrade', report);
				})
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
	},
};
</script>
