import MessageCenter from '@/components/MessageCenter.vue';

export default {
	install(Vue) {
		Vue.component('message-center', MessageCenter);
		Vue.mixin({
			methods: {
				flashMessage(type, message, closeable = true) {
					this.$root.$emit('flash-message', { type, message, closeable });
				},
			},
		});
	},
};
