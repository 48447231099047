<template lang="html">
	<div class="confirm">
		<div class="confirm__fade" v-if="isAsking">
			<div class="confirm__dialog">
				<slot name="question">{{ question }}</slot>
				<footer>
					<button
						@click="accept"
						:disabled="!isValid"
						class="confirm__accept">
						{{ acceptance }}
					</button>
					<button class="confirm__refuse" @click="refuse">{{ refusal }}</button>
				</footer>
			</div>
		</div>
		<a href="#" @click.prevent="ask"><slot/></a>
	</div>
</template>

<script>
export default {
	name: 'ConfirmDialog',
	props: {
		question: { type: String, default() { return this.$t('dialog.sure'); } },
		acceptance: { type: String, default() { return this.$t('global.button.accept'); } },
		refusal: { type: String, default() { return this.$t('global.button.cancel'); } },
		isValid: { type: Boolean, default: true },
	},
	data() {
		return { isAsking: false };
	},
	methods: {
		ask() { this.isAsking = true; },
		accept() {
			this.isAsking = false;
			this.$emit('accept');
		},
		refuse() {
			this.isAsking = false;
			this.$emit('refuse');
		},
	},
};
</script>
