<template lang="html">
	<label
		@drop.prevent="handleFile"
		@dragover.prevent
		:class="`uploader uploader--${status}`">
		<slot>
			<i class="mdi mdi-cloud-upload" />
			{{ error || (file && file.name) || placeholder }}
		</slot>
		<input type="file" name="file" :accept="accept" @change="handleFile" />
	</label>
</template>

<script>
import api from '@/api';

export default {
	name: 'FileUploader',
	props: {
		url: { type: String, default: '' },
		placeholder: { type: String, default: 'Carrega un fitxer' },
		filename: { type: String, default: undefined },
		maxFileSize: { type: Number, default: Infinity },
		accept: { type: String, default: undefined },
	},
	data() {
		return {
			file: undefined,
			status: 'idle',
			error: undefined,
		};
	},
	methods: {
		handleFile(e) {
			this.status = 'working';
			this.error = undefined;
			[this.file] = e.target.files || e.dataTransfer.files;
			if (this.isValid(this.file)) {
				if (this.url) this.uploadFile(this.file);
				else {
					this.status = 'success';
					this.$emit('load', this.file);
				}
			}
		},
		uploadFile(file) {
			const formData = new FormData();
			formData.append('file', file, this.filename || file.name);
			api.post(this.url, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
				.then(({ data }) => {
					this.status = 'success';
					this.$emit('upload', data);
				}).catch(({ response: { data } }) => {
					this.status = 'error';
					this.error = data;
					this.$emit('fail', data);
				});
		},
		isValid(file) {
			if (!this.accept || file.type.match(this.accept)) {
				if (file.size < this.maxFileSize * 1000) return true;
				this.error = this.$t('feedback.error.file_size');
			} else this.error = this.$t('feedback.error.file_type');
			this.status = 'error';
			this.$emit('load', null);
			this.$emit('fail', this.error);
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.uploader {
	display: inline-block;
	cursor: pointer;

	input[type=file] { display: none }
}
</style>
