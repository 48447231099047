import Vue from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './i18n';
import MessageCenter from './message-center';
import 'reset-css'; // eslint-disable-line import/extensions
import '@/styles/main.scss';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

Vue.use(MessageCenter);

new Vue({
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
