import api from '@/api';
import csvLayouts from '@/assets/csv-layouts/index';

const exportCSV = (url, layout) => new Promise((resolve, reject) => {
	try {
		api.get(url).then(({ data }) => {
			const csv = csvLayouts[layout](data);
			const encoded = encodeURI(`data:text/csv;charset=utf-8,${csv}`);
			const a = document.createElement('a');
			a.setAttribute('href', encoded);
			a.setAttribute('download', `${layout}_${new Date()}.csv`);
			a.click();
			resolve();
		}).catch((error) => reject(error));
	} catch (e) { reject(e); }
});

export default exportCSV;
