<template lang="html">
	<ul class="progress">
		<li v-for="(name, step) in steps" :key="name" :class="stepClass(step)">
			<a href="#" @click.prevent="changeStep(step)">
				<span>{{ $t(`${sector}.${name}.name`) }}</span>
			</a>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'StepProgress',
	props: {
		sector: { type: String, required: true },
		steps: { type: Array, required: true },
		value: { type: Number, default: 0 },
	},
	methods: {
		stepClass(step) {
			return ['progress__step', {
				'progress__step--active': step === this.value,
				'progress__step--done': step < this.value,
			}];
		},
		changeStep(step) {
			this.$emit('input', step);
		},
	},
};
</script>
