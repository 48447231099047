import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		digicert: undefined,
		report: {
			editable: true,
			id: undefined,
			type: undefined,
			details: {},
			emissions: {},
		},
	},
	getters: {
		refreshToken: ({ digicert }) => (digicert ? digicert.refresh_token : ''),
		isReportEditable: ({ report: { editable } }) => editable,
		getDetails: ({ report: { details } }) => details,
		getEmissions: ({ report: { emissions } }) => (path) => {
			if (!path) return emissions;
			const [step, table] = path.split('.');
			return !emissions[step] || emissions[step][table] === undefined
				? []
				: emissions[step][table];
		},
	},
	mutations: {
		/* eslint-disable no-param-reassign */
		setDigicert(state, digicert) { state.digicert = digicert; },
		setAvatar(state, { target, avatar }) { state.digicert[target].avatar = avatar; },
		setReport(state, report) { state.report = report; },
		setDetails({ report }, details) { report.details = details; },
		setEmissions({ report }, { path, emissions }) {
			if (!path) report.emissions = emissions;
			else {
				const [step, table] = path.split('.');
				if (!report.emissions[step]) Vue.set(report.emissions, step, {});
				if (!report.emissions[step][table]) Vue.set(report.emissions[step], table, []);
				report.emissions[step][table] = emissions;
			}
		},
		clearReport(state, type = undefined) {
			state.report = { type, editable: true, id: undefined, details: {}, emissions: {} };
		},
		/* eslint-enable no-param-reassign */
	},
});
