import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ca from './locales/ca/index';

Vue.use(VueI18n);

const messages = { ca };

export default new VueI18n({
	locale: 'ca',
	fallbackLocale: 'ca',
	messages,
});
