export default {
	sure: 'Estas segur?',
	unsave_exit: 'Segur que vols sortir? Totes les dades no guardades es perdran',
	report_upgrade: 'Aquesta acció és irreversible. Vols continuar?',
	report_downgrade: 'Aquesta acció és irreversible. Vols continuar?',
	report_delete: 'Segur que vols eliminar aquest informe?',
	report_transfer: "Selecciona la organització a la qual vols transferir la propietat d'aquest informe. Aquesta acció és irreversible.",

	report_compensation: {
		details: 'Compensa emissions comprant crèdits de carboni certificats, per exemple a través de {link}. Afegeix la quantitat compensada i el certificat en format pdf.',
		link: 'projectes de Nacions Unides',
		amount: 'CO₂ compensat',
		file_description: 'Certificat compensació',
	},
};
