<template lang="html">
	<section class="user-zone-admin">
		<div class="user-zone-admin__reports">
			<h4>{{ $t('global.heading.reports') }}</h4>
			<reports-list
				filters="status=VLD,CMP"
				:view="['VLD','CMP']"
				generate
				:downgrade="['DFT','VLD']"
				:remove="['VLD','CMP']"
				exportable
				@close="$emit('close')">
				<template v-slot:header>
					<span class="grid__col--5">{{ $t('report.detail.name') }}</span>
					<span class="grid__col--1">{{ $t('report.detail.owner') }}</span>
					<span class="grid__col--3">{{ $t('report.detail.sector') }}</span>
					<span class="grid__col--3">{{ $t('report.detail.status') }}</span>
				</template>
				<template v-slot:body="{ report }">
					<span class="grid__col--5">{{ report.name }}</span>
					<span
						:data-tooltip="report.owner ? report.owner.name : report.author.name"
						class="grid__col--1">
						<img :src="getAvatar(report.owner || report.author)" class="avatar" />
					</span>
					<span class="grid__col--3">
						<i class="tag">{{ $t(`report.type.${report.type}`) }}</i>
					</span>
					<span class="grid__col--3">
						<i :class="`tag tag--${report.status}`">
							{{ $t(`report.status.${report.status}`) }}
						</i>
					</span>
				</template>
			</reports-list>
		</div>
		<aside class="user-zone-admin__admins">
			<h4>{{ $t('global.heading.admins') }}</h4>
			<p class="note">{{ $t('global.tip.manage_admin') }}</p>
			<p><dropdown-select :options="notAdmins" v-model="newAdmin" searchable /></p>
			<p>
				<span v-for="admin in admins" :key="admin.id" class="tag tag--fill">
					<em
						v-if="notSelf(admin)"
						@click="setAdmin(admin)"
						class="tag__close">
						&times;
					</em>
					{{ admin.name }}
				</span>
			</p>
		</aside>
	</section>
</template>

<script>
import api from '@/api';
import { getAvatar } from '@/utils';
import ReportsList from '@/components/ReportsList.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';

const isAdmin = ({ role }) => role === 'admin';

export default {
	name: 'UserZoneAdmin',
	props: { digicert: { type: Object, required: true } },
	components: { ReportsList, DropdownSelect },
	data() {
		return {
			users: [],
			newAdmin: undefined,
		};
	},
	computed: {
		admins() { return this.users.filter(isAdmin); },
		notAdmins() { return this.users.filter((user) => !isAdmin(user)); },
	},
	watch: {
		newAdmin(user) {
			this.setAdmin(user, true);
			this.newAdmin = undefined;
		},
	},
	methods: {
		getAvatar,
		notSelf(user) { return user.id !== this.digicert.user.id; },
		setAdmin(user, beAdmin = false) {
			if (!user) return;
			api.put(`/users/${user.id}`, { role: beAdmin ? 'admin' : 'user' })
				.then(({ data }) => {
					user.role = data.role; // eslint-disable-line no-param-reassign
					this.flashMessage('success', this.$t('feedback.success.admin_update'));
				}).catch(({ response: { data } }) => this.flashMessage('error', data));
		},
	},
	mounted() {
		api.get('/users').then(({ data }) => {
			this.users = data.map((user) => ({ ...user, label: user.name }));
		}).catch(({ response: { data } }) => this.flashMessage('error', data));
	},
};
</script>
