<template lang="html">
	<div class="message-center">
		<p v-if="controls && messages.length" class="message-center__controls">
			<a href="#" @click.prevent="clear">CLEAR</a>
		</p>
		<p v-for="(msg, i) in messages" :key="i" :class="`msg msg--${msg.type}`">
			{{ msg.message }}
			<span @click="remove(i)" v-if="msg.closeable" class="msg__close">&times;</span>
		</p>
	</div>
</template>

<script>
export default {
	name: 'MessageCenter',
	props: {
		timeout: { type: Number, default: 0 },
		controls: { type: Boolean, default: false },
	},
	data() { return { messages: [] }; },
	methods: {
		clear() { this.messages = []; },
		remove(i) { this.messages.splice(i, 1); },
		add({ type, message, closeable = true }) {
			this.messages.push({ type, message, closeable });
			if (this.timeout) setTimeout(() => { this.messages.shift(); }, this.timeout * 1000);
		},
	},
	created() { this.$root.$on('flash-message', this.add); },
};
</script>

<style lang="scss">
.message-center {
	position: fixed;
	z-index: 10;
}
</style>
