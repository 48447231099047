<template lang="html">
	<section class="user-zone-self">
		<h4>{{ $t('global.heading.reports') }}</h4>
		<reports-list
			:filters="reportsFilter"
			:upgrade="['VLD','CMP']"
			duplicate
			generate
			:remove="['DFT']"
			:transfer.sync="transfer"
			@close="$emit('close')">
			<template v-slot:header>
				<span class="grid__col--5">{{ $t('report.detail.name') }}</span>
				<span class="grid__col--1">
					{{ $t(`report.detail.${isPersonal ? 'owner' : 'author' }`) }}
				</span>
				<span class="grid__col--2">{{ $t('report.detail.status') }}</span>
				<span class="grid__col--2">{{ $t('report.detail.created') }}</span>
				<span class="grid__col--2">{{ $t('report.detail.modified') }}</span>
			</template>
			<template v-slot:body="{ report }">
				<span class="grid__col--5">{{ report.name }}</span>
				<span
					:data-tooltip="isPersonal && report.owner
						? report.owner.name
						: report.author.name"
					class="grid__col--1 content-center">
					<img
						:src="getAvatar(isPersonal && report.owner ? report.owner : report.author)"
						class="avatar" />
				</span>
				<span class="grid__col--2">
					<i :class="`tag tag--${report.status}`">
						{{ $t(`report.status.${report.status}`) }}
					</i>
				</span>
				<span class="grid__col--2">{{ report.created_at | shortDate }}</span>
				<span class="grid__col--2">{{ report.updated_at | shortDate }}</span>
			</template>
		</reports-list>
	</section>
</template>

<script>
import api from '@/api';
import ReportsList from '@/components/ReportsList.vue';
import { getAvatar, shortDate } from '@/utils';

export default {
	name: 'UserZoneSelf',
	props: { digicert: { type: Object, required: true } },
	components: { ReportsList },
	filters: { shortDate },
	data() {
		return {
			user: undefined,
			transfer: { options: [], selected: undefined },
		};
	},
	computed: {
		isPersonal() { return !this.digicert.organization; },
		reportsFilter() {
			const { user, organization } = this.digicert;
			return this.isPersonal ? `author=${user.id}` : `owner=${organization.id}`;
		},
	},
	methods: { getAvatar },
	mounted() {
		api.get('/users/me').then(({ data }) => {
			this.user = data;
			this.transfer.options = data.organizations.map((org) => ({ ...org, label: org.name }));
		}).catch(({ response: { data } }) => this.flashMessage('error', data));
	},
};
</script>
