import Avatar from '@/assets/img/avatar.png';

// Defaults
const apiURL = process.env.VUE_APP_ROOT_API;
const getApiPath = (path) => `${apiURL}/${path}`;
const getAvatar = ({ avatar } = {}) => (avatar ? `${apiURL}/${avatar}?k=${Math.random()}` : Avatar);

// Numbers
const round = (number) => Math.round(number * 100) / 100;
const toTones = (number) =>	round(number / 1000);

// Dates
const dateFormat = {
	short: { year: '2-digit', month: '2-digit', day: '2-digit' },
	long: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
};
const fixDate = (date) => new Date(date.toString().replace(/[+-]([0-9]{2})([0-9]{2})\b/, '+$1:$2'));
const dateString = (date, opt = dateFormat.long) => fixDate(date).toLocaleDateString('ca-ES', opt);
const shortDate = (date) => dateString(date, dateFormat.short);
const longDate = (date) => dateString(date, dateFormat.long);

// Objects & arrays
const isEmpty = (obj) => !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
const reduceEmissions = (obj) => Object.entries(obj).map(([name, items]) => {
	if (!items) return { name, total: 0 };
	const emissions = Array.isArray(items) ? undefined : reduceEmissions(items);
	const total = emissions
		? emissions.reduce((acc, { total: t }) => acc + t, 0)
		: items.reduce((acc, { emissions: e }) => acc + e, 0);
	return { name, emissions: emissions || [], total: round(total) };
});

const serialize = (value) => {
	if (Array.isArray(value)) return [...new Set(value)].map((v) => serialize(v)).join(' - ');
	if (!Number.isNaN(Number(value.toString()))) return value;
	if (value instanceof Date || Date.parse(value)) return longDate(value);
	if (value.label) return value.label;
	return value;
};

export {
	getAvatar,
	getApiPath,
	reduceEmissions,
	fixDate,
	dateString,
	shortDate,
	longDate,
	round,
	toTones,
	serialize,
	isEmpty,
};
