<template lang="html">
  <div class="modal">
		<div class="modal__fade" v-if="isVisible">
			<div class="guided-tour modal__dialog">
				<img :src="`/img/guided-tour/${steps[step].img}`" class="cover">
				<div class="modal__main guided-tour__main">
					<p>{{ steps[step].text }}</p>
					<p class="note">
						+info:
						<a href="media/guia_usuari.pdf" target="blank">
							{{ $t('guided_tour.link.user_guide') }}
						</a> |
						<a href="media/guia_metodologica.pdf" target="blank">
							{{ $t('guided_tour.link.methodology_guide') }}
						</a> |
						<a href="mailto:andorrasostenible@andorra.ad">
							{{ $t('guided_tour.link.email') }}
						</a>
					</p>
				</div>
				<footer class="columns">
					<span class="column">
						<button class="btn" v-if="step" @click="prev">
							{{ $t('global.button.prev') }}
						</button>
						<button class="btn" v-if="nextStep" @click="next">
							{{ $t('global.button.next') }}
						</button>
					</span>
					<span class="column--right">
						<button class="btn btn--outline" @click.prevent="close">
							{{ $t('global.button.skip') }}
						</button>
					</span>
				</footer>
			</div>
		</div>
		<a href="#" class="modal__toggle" @click.prevent="open">
			<slot />
		</a>
	</div>
</template>

<script>
export default {
	name: 'GuidedTour',
	data() {
		return {
			isVisible: false,
			step: 0,
			steps: [
				{ text: this.$t('guided_tour.step.welcome'), img: 'welcome.jpg' },
				{ text: this.$t('guided_tour.step.help'), img: 'help.jpg' },
				{ text: this.$t('guided_tour.step.move_steps'), img: 'move-steps.jpg' },
				{ text: this.$t('guided_tour.step.emissions_table'), img: 'emissions-table.jpg' },
				{ text: this.$t('guided_tour.step.alert_tooltip'), img: 'alert-tooltip.jpg' },
				{ text: this.$t('guided_tour.step.reports_list'), img: 'reports-list.jpg' },
			],
		};
	},
	computed: {
		nextStep() {
			return this.step < this.steps.length - 1;
		},
	},
	methods: {
		open() {
			this.step = 0;
			this.isVisible = true;
		},
		close() { this.isVisible = false; },
		next() { this.step += 1; },
		prev() { this.step -= 1; },
	},
};
</script>
