export default {

	success: {
		avatar_update: 'Avatar actualitzat amb èxit',
		admin_update: 'Administrador actualitzat amb èxit',
		pdf_create: 'PDF generat amb èxit.',
		csv_create: 'CSV generat amb èxit.',
		report_save: 'Informe guardat amb èxit',
		report_update: 'Informe actualitzat amb èxit',
		report_duplicate: 'Informe duplicat amb èxit',
		report_transfer: 'Informe transferit amb èxit',
		report_delete: 'Informe eliminat amb èxit',
		report_upgrade_VLD: 'Informe validat amb èxit',
		report_upgrade_CMP: 'Informe compensat amb èxit',
		report_upgrade_CRT: 'Informe certificat amb èxit',
		report_downgrade: 'Informe degradat amb èxit',
	},

	error: {
		session_init: "S'ha d'iniciar sessió amb el certificat digital del Govern d'Andorra",
		pdf_create: "El PDF no s'ha pogut generar",
		csv_create: "El CSV no s'ha pogut generar",
		file_type: "L'extensió del fitxer no és permesa",
		file_size: 'El fitxer és massa gran',
	},

	warning: { popup_block: "Atenció! Assegura't que el teu navegador no bloqueja les finestres emergents" },

	start: {
		pdf_create: 'Generant informe en PDF...',
		csv_create: 'Generant resultats en CSV...',
	},

};
