import global from './global';
import dialog from './dialog';
import feedback from './feedback';
import report from './report';
import tooltip from './tooltip';
import event from './event';
import residential from './residential';
import organization from './organization';
import calendar from './calendar';
import guidedTour from './guided-tour';

export default {
	global,
	dialog,
	feedback,
	report,
	tooltip,
	event,
	residential,
	organization,
	calendar,
	guided_tour: guidedTour,
};
