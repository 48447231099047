<template lang="html">
	<div class="reports-list">
		<div class="reports-list__toolbox columns">
			<div class="column">
				<input
					type="text"
					v-model="search"
					:placeholder="$t('global.default.search.placeholder')" />
			</div>
			<div class="column--right">
				<button v-if="exportable" @click="exportReports" class="btn">
					<i class="mdi mdi-download" /> {{ $t('global.button.export') }}
				</button>
			</div>
		</div>
		<ul>
			<li class="reports-list__header">
				<slot name="header" />
			</li>
			<li
				v-for="report in searchReports"
				:key="report.id"
				tabindex="1"
				class="reports-list__item more">
				<slot name="body" :report="report" />
				<div class="reports-list__actions more__content">
					<span
						:data-tooltip="$t(`report.action.${actionViewEdit(report)}`)"
						@click="viewReport(report)">
						<i :class="`icon-btn
							mdi mdi-${report.status == 'DFT' ? 'pencil' : 'eye'}`" />
					</span>
					<span
						v-if="duplicate"
						:data-tooltip="$t('report.action.duplicate')"
						@click="duplicateReport(report)">
						<i class="mdi mdi-content-copy icon-btn" />
					</span>
					<confirm-dialog
						v-if="transfer && report.type !== 'DOM' && !report.owner"
						:data-tooltip="$t(`report.action.transfer`)"
						:acceptance="$t('report.action.transfer')"
						:refusal="$t('global.button.cancel')"
						:is-valid="!!transfer.selected"
						@accept="transferReport(report)">
						<template v-slot:question>
							<p>{{ $t('dialog.report_transfer') }}</p>
							<p>
								<dropdown-select
									:options="transfer.options"
									v-model="transferSelected" />
							</p>
						</template>
						<i class="mdi mdi-transfer-right icon-btn" />
					</confirm-dialog>
					<span
						v-if="generate && report.status !== 'DFT'"
						:data-tooltip="$t('report.action.download_report')"
						@click="generateReport(report)">
						<i class="mdi mdi-file-table icon-btn" />
					</span>
					<a
						v-if="report.compensation"
						:data-tooltip="$t('report.action.download_cert')"
						:href="getApiPath(report.compensation.file)"
						:download="`${report.name} [Certificat]`"
						target="blank">
						<i class="mdi mdi-file-certificate icon-btn" />
					</a>
					<report-upgrader
						:allow="upgrade"
						:report="report"
						@upgrade="data => upgradeReport(data)" />
					<report-downgrader
						:allow="downgrade"
						:report="report"
						@upgrade="data => downgradeReport(data)" />
					<confirm-dialog
						v-if="remove.includes(report.status)"
						:data-tooltip="$t('report.action.delete')"
						:question="$t('dialog.report_delete')"
						:acceptance="$t('global.button.delete')"
						:refusal="$t('global.button.cancel')"
						@accept="removeReport(report)">
						<i class="mdi mdi-trash-can icon-btn icon-btn--emphasis" />
					</confirm-dialog>
				</div>
			</li>
			<li v-if="!searchReports.length" class="reports-list__empty">
				{{ $t('report.detail.empty') }}
			</li>
		</ul>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import pdfMake from '@/pdfmake';
import csvExport from '@/csv-export';
import api from '@/api';
import { getApiPath } from '@/utils';
import ReportUpgrader from '@/components/ReportUpgrader.vue';
import ReportDowngrader from '@/components/ReportDowngrader.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';

const normalize = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
const match = (text, search) => (search ? normalize(text).includes(search) : true);

export default {
	name: 'ReportsList',
	components: { ReportUpgrader, ReportDowngrader, ConfirmDialog, DropdownSelect },
	props: {
		filters: { type: String, default: '' },
		view: { type: Array, default: () => [] },
		upgrade: { type: Array, default: () => [] },
		downgrade: { type: Array, default: () => [] },
		edit: { type: Boolean, default: false },
		remove: { type: Array, default: () => [] },
		generate: { type: Boolean, default: false },
		duplicate: { type: Boolean, default: false },
		transfer: { type: Object, default: null },
		exportable: { type: Boolean, default: false },
	},
	data() {
		return {
			reports: [],
			search: '',
		};
	},
	computed: {
		searchReports() {
			const search = this.search && normalize(this.search);
			return this.reports.filter(({ name, author, owner, status }) => {
				const isVisible = !this.view.length || this.view.includes(status);
				const isMatch = match(name, search)
					|| (!owner && match(author.name, search))
					|| (owner && match(owner.name, search));
				return isVisible && isMatch;
			});
		},
		transferSelected: {
			get() { return this.transfer.selected; },
			set(selected) { this.$emit('update:transfer', { ...this.transfer, selected }); },
		},
	},
	methods: {
		...mapMutations(['setReport']),
		getApiPath,
		actionViewEdit(report) { return report.status === 'DFT' ? 'edit' : 'view'; },
		fetchReports() {
			const filters = this.filters ? `?${this.filters}` : '';
			api.get(`/reports${filters}`)
				.then(({ data }) => { this.reports = data; })
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		reportIndex(id) { return this.reports.findIndex((report) => report.id === id); },
		upgradeReport(report) {
			this.updateReport(report);
			this.flashMessage('success', this.$t(`feedback.success.report_upgrade_${report.status}`));
		},
		downgradeReport(report) {
			this.updateReport(report);
			this.flashMessage('success', this.$t('feedback.success.report_downgrade'));
		},
		updateReport({ id, status, compensation }) {
			const index = this.reportIndex(id);
			this.reports[index].status = status;
			this.reports[index].compensation = compensation;
		},
		viewReport({ id, status }) {
			const editable = status === 'DFT';
			api.get(`/reports/${id}`)
				.then(({ data }) => {
					const { type, content: { details, emissions } } = data;
					this.setReport({ editable, id, type, details, emissions });
					this.$emit('close');
				}).catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		duplicateReport({ id }) {
			api.get(`/reports/${id}`).then(({ data }) => {
				const { type, name, content } = data;
				content.details.name = `${name} - còpia`;
				api.post('/reports', { type, name: content.details.name, content })
					.then(({ data }) => {
						this.reports.push(data);
						this.flashMessage('success', this.$t('feedback.success.report_duplicate'));
					}).catch(({ response: { data } }) => this.flashMessage('error', data));
			}).catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		transferReport({ id }) {
			api.put(`/reports/${id}`, { owner_id: this.transfer.selected.id })
				.then(({ data }) => {
					this.reports[this.reportIndex(id)].owner = data.owner;
					this.flashMessage('success', this.$t('feedback.success.report_transfer'));
					this.transferSelected = undefined;
				})
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		generateReport({ id }) {
			this.flashMessage('success', this.$t('feedback.start.pdf_create'));
			api.get(`/reports/${id}?avatar_format=base64`)
				.then(({ data: report }) => {
					pdfMake(report)
						.then(() => this.flashMessage('success', this.$t('feedback.success.pdf_create')))
						.catch(() => this.flashMessage('error', this.$t('feedback.error.pdf_create')));
				}).catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		removeReport({ id }) {
			api.delete(`/reports/${id}`)
				.then(() => {
					this.reports.splice(this.reportIndex(id), 1);
					this.flashMessage('success', this.$t('feedback.success.report_delete'));
				})
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		exportReports() {
			this.flashMessage('success', this.$t('feedback.start.csv_create'));
			const filters = this.filters ? `?${this.filters}&extended=true` : '?extended=true';
			csvExport(`/reports${filters}`, 'reports')
				.then(() => this.flashMessage('success', this.$t('feedback.success.csv_create')))
				.catch(() => this.flashMessage('error', this.$t('feedback.error.csv_create')));
		},
	},
	mounted() { this.fetchReports(); },
};
</script>
