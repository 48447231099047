<template lang="html">
	<div class="modal">
		<div v-if="isVisible && digicert" class="modal__fade">
			<div class="user-zone modal__dialog">
				<header class="modal__header">
					<div class="modal__topbar columns">
						<h3 class="column">{{ $t('global.heading.user_zone') }}</h3>
						<div class="column column--right">
							<button @click="logout" class="btn">
								<i class="mdi mdi-power" />
								{{ $t('global.button.logout') }}
							</button>
							<a href="#" @click.prevent="close" class="modal__close">&times;</a>
						</div>
					</div>
					<section class="user-zone__details columns">
						<div class="column profile">
							<file-uploader
								url="/users/me/avatar"
								accept="image/*"
								:max-file-size="50"
								@fail="msg => flashMessage('error', msg)"
								@upload="avatar => updateAvatar('user', avatar)">
								<img :src="getAvatar(digicert.user)" class="avatar"/>
							</file-uploader>
							<p>
								<em>{{ $t('global.default.name') }}</em>
								{{ digicert.user.name }}
							</p>
						</div>
						<div v-if="digicert.type != 'PRS'" class="column profile">
							<file-uploader
								url="/organizations/me/avatar"
								accept="image/*"
								:max-file-size="50"
								@fail="msg => flashMessage('error', msg)"
								@upload="avatar => updateAvatar('organization', avatar)">
								<img :src="getAvatar(digicert.organization)" class="avatar" />
							</file-uploader>
							<p>
								<em>{{ $t('global.default.organization') }}</em>
								{{ digicert.organization.name }}
							</p>
						</div>
						<div class="column column--right">
							<label class="choice-tag">
								<input type="radio" v-model="section" value="self" />
								<span class="tag">
									{{ $t(`global.digicert.type.${digicert.type}`) }}
								</span>
							</label>
							<label v-if="digicert.user.role !== 'user'" class="choice-tag">
								<input type="radio" v-model="section" :value="digicert.user.role" />
								<span class="tag">
									{{ $t(`global.digicert.role.${digicert.user.role}`) }}
								</span>
							</label>
						</div>
					</section>
				</header>
				<div class="modal__main">
					<component
						:is="`user-zone-${section}`"
						:digicert="digicert"
						@close="close" />
				</div>
			</div>
		</div>
		<span class="modal__toggle" @click.prevent="open">
			<slot>
				<span v-if="digicert">
					<img class="avatar" :src="getAvatar(digicert.user)" />
				</span>
				<a href="#" v-else>
					{{ $t('global.button.login') }}
				</a>
			</slot>
		</span>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import api from '@/api';
import { getAvatar } from '@/utils';
import UserZoneSelf from '@/views/user-zone/UserZoneSelf.vue';
import UserZoneAdmin from '@/views/user-zone/UserZoneAdmin.vue';
import FileUploader from '@/components/FileUploader.vue';

export default {
	name: 'UserZoneView',
	components: { UserZoneSelf, UserZoneAdmin, FileUploader },
	data() {
		return {
			isVisible: false,
			section: 'self',
			popup: null,
		};
	},
	computed: mapState(['digicert']),
	methods: {
		...mapMutations(['setDigicert', 'setAvatar']),
		getAvatar,
		open() {
			if (this.digicert) this.isVisible = true;
			else this.login();
		},
		close() { this.isVisible = false; },
		login() {
			api.get('/auth')
				.then(({ data }) => {
					this.setDigicert(data);
					api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
					this.section = 'self';
					this.isVisible = true;
					this.popup.close();
				})
				.catch(() => {
					this.flashMessage('info', this.$t('feedback.error.session_init'));
					this.flashMessage('warning', this.$t('feedback.warning.popup_block'));
					this.popup = window.open(process.env.VUE_APP_SAML_AUTH, 'auth', 'height=620, width=1024');
				});
		},
		logout() {
			const { protocol, hostname } = window.location;
			const url = `${protocol}//${hostname}
				/Shibboleth.sso/Logout?return=https://autenticacio.govern.ad/idp/profile/Logout`;
			window.open(url, 'auth', 'height=620, width=1024');
			this.setDigicert(undefined);
			api.defaults.headers.common.Authorization = undefined;
			this.close();
		},
		updateAvatar(target, { avatar }) {
			// Add key to URL to skip image caching and force avatar redraw
			this.setAvatar({ target, avatar });
			this.flashMessage('success', this.$t('feedback.success.avatar_update'));
		},
		onStorageUpdate(event) {
			if (event.key === 'saml_auth') this.login();
		},
	},
	created() { window.addEventListener('storage', this.onStorageUpdate); },
	beforeDestroy() { window.removeEventListener('storage', this.onStorageUpdate); },
};
</script>
