import Axios from 'axios';
import store from '@/store';

// Middleware interface to connect to third APIs
const api = Axios.create({
	baseURL: process.env.VUE_APP_ROOT_API,
	timeout: 5000,
});

const refreshToken = async () => {
	const headers = { Authorization: `Bearer ${store.getters.refreshToken}` };
	const { data } = await api.put('/auth', null, { headers });
	api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
	return data.access_token;
};

const handleRefreshFailure = (error) => {
	store.state.digicert = undefined;
	api.defaults.headers.common.Authorization = undefined;
	return Promise.reject(error);
};

api.interceptors.response.use(null, (error) => {
	const { config, response } = error;
	const { url, method } = config;
	if (response.status !== 401) return Promise.reject(error);
	if (url.includes('/auth') && method === 'put') return handleRefreshFailure(error);
	return refreshToken().then((token) => {
		config.headers.Authorization = `Bearer ${token}`;
		return api.request(config);
	});
});

export default api;
