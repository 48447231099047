<template lang="html">
	<div :class="['dropdown', { 'disabled': disabled || isEmpty }]">
		<input
			type="text"
			v-model="search"
			:placeholder="placeholder"
			:disabled="disabled || isEmpty"
			:readonly="!searchable" />
		<ul v-if="!disabled && !isEmpty" class="dropdown__options">
			<li
				v-for="option in searchOptions"
				:key="option.label || option"
				@click="select(option)">
				{{ option.label || option }}
			</li>
			<span v-if="isEmptySearch" class="dropdown__not-found">{{ notFoundText }}</span>
		</ul>
	</div>
</template>

<script>
const normalize = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
const match = (text, search) => (search ? normalize(text).includes(search) : true);

export default {
	name: 'DropdownSelect',
	props: {
		options: { type: Array, required: true },
		value: { required: true, default: (value) => value !== undefined },
		placeholder: {
			type: String,
			default() { return this.$t('global.default.dropdown.placeholder'); },
		},
		notFoundText: {
			type: String,
			default() { return this.$t('global.default.dropdown.not_found'); },
		},
		searchable: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},
	data() { return { search: undefined }; },
	computed: {
		isEmpty() {
			return !this.options.length;
		},
		isEmptySearch() {
			return !this.searchOptions.length;
		},
		searchOptions() {
			if (!this.searchable) return this.options;
			const search = this.search && normalize(this.search);
			return this.options.filter(({ label }) => match(label, search));
		},
	},
	watch: {
		options() { // Reset selection when new options are available
			this.select(undefined);
		},
		value: {
			immediate: true,
			handler(value) {
				const invalid = !value || Object.keys(value).length === 0;
				this.search = invalid ? '' : value.label || value;
			},
		},
	},
	methods: {
		select(option) {
			this.$emit('input', option);
		},
	},
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: inline-block;
  position: relative;

  &__options {
    display: none;
    position: absolute;
    top: 100%;
    min-width: 100%;
		list-style-type: none;
    box-sizing: border-box;
		z-index: 1;

    &:hover,
    .dropdown:focus-within & { display: block; }
  }
}
</style>
