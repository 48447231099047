export default {

	heading: {
		user_zone: 'Zona Usuari',
		reports: 'Informes',
		admins: 'Administradors',
		results: 'Resultats',
	},

	button: {
		prev: 'Anterior',
		next: 'Següent',
		reset: 'Buidar',
		skip: 'Ometre',
		exit: 'Sortir',
		add: 'Afegir',
		accept: 'Acceptar',
		delete: 'Eliminar',
		save: 'Guardar',
		update: 'Guardar canvis',
		cancel: 'Cancel·lar',
		login: 'Iniciar sessió',
		logout: 'Tancar sessió',
		export: 'Exportar',
	},

	tip: {
		mandatory: "Aquest apartat és d'obligatori compliment",
		skip: 'No hi ha emissions en aquest apartat',
		login_to_save: "Per guardar aquest informe has d'haver iniciat sessió",
		manage_admin: "Gestiona els usuaris amb permisos d'administrador",
	},

	digicert: {
		type: {
			PRS: 'Ciutadà',
			CRP: 'Empleat',
			FUN: 'Funcionari',
			CLG: 'Col·legiat',
			AUT: 'Autònom',
			RPF: 'Representant persona física',
			RPJ: 'Representant empresa',
		},
		role: {
			user: 'Usuari',
			admin: 'Admin',
		},
	},

	default: {
		hours: 'hores',
		km: 'km',
		name: 'Nom',
		people: 'persones',
		organization: 'Organització',
		dropdown: {
			placeholder: 'Selecciona una opció',
			not_found: 'No hi ha resultats',
		},
		search: { placeholder: 'Cercar' },
		datepicker: { placeholder: 'Selecciona una data' },
	},

};
