export default {
	step: {
		welcome: "Benvingut/da! Aquesta plataforma permet calcular les emissions de CO₂ de ciutadans, organitzacions i esdeveniments d'Andorra.",
		help: "Fes clic a l'icona (?) per iniciar la guia interactiva, on també hi trobaràs la guia d'usuari, la guia metodològica i un correu per a dubtes. Fes clic a 'Iniciar sessió' per entrar a la zona d'usuari amb el certificat digital de Govern.",
		move_steps: 'Avança entre els diferents passos de la calculadora. En determinats casos és necessari completar uns camps obligatoris abans de poder continuar.',
		emissions_table: 'Afegeix i elimina emissions de les taules mitjançant els botons i formularis. Atenció als indicadors que adverteixen sobre requisits previs i unitats a utilitzar.',
		alert_tooltip: 'Atenció als indicadors vermells, la informació que donen és important i podria passar-se per alt.',
		reports_list: "Els informes d'emissions es gestionen des de la zona d'usuari, amb accions com veure, editar, duplicar, validar, compensar, eliminar, etc.",
	},

	link: {
		user_guide: 'Guia usuari',
		methodology_guide: 'Guia metodològica',
		email: 'Correu dubtes',
	},
};
