<template>
  <div id="app">
		<message-center :timeout="5" controls />
		<header class="header columns">
			<div class="column--right">
				<guided-tour>
					<i class="mdi mdi-help-rhombus icon icon--big" />
				</guided-tour>
				<span class="separator">.</span>
				<user-zone-view />
			</div>
		</header>
		<calculator-view v-if="sector" :sector="sector" @exit="reset()" />
		<div v-else class="hero">
			<div class="cards">
				<div class="card">
					<img src="@/assets/img/residential.png" class="card__cover" />
					<p class="content-center">
						<button class="btn" @click="reset('DOM')">
							{{ $t('residential.title') }}
						</button>
					</p>
					<p class="note">{{ $t('residential.tagline') }}</p>
				</div>
				<div class="card">
					<img src="@/assets/img/organizations.png" class="card__cover" />
					<p class="content-center">
						<button class="btn" @click="reset('ORG')">
							{{ $t('organization.title') }}
						</button>
					</p>
					<p class="note">{{ $t('organization.tagline') }}</p>
				</div>
				<div class="card">
					<img src="@/assets/img/event.png" class="card__cover" />
					<p class="content-center">
						<button class="btn" @click="reset('EVT')">
							{{ $t('event.title') }}
						</button>
					</p>
					<p class="note">{{ $t('event.tagline') }}</p>
				</div>
			</div>
			<p class="attribution">
				Vectors by <a href="https://www.freepik.com/macrovector" target="blank">macrovector</a>
			</p>
		</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UserZoneView from '@/views/UserZoneView.vue';
import CalculatorView from '@/views/CalculatorView.vue';
import GuidedTour from '@/components/GuidedTour.vue';

export default {
	name: 'app',
	components: { UserZoneView, CalculatorView, GuidedTour },
	computed: {
		...mapState(['report']),
		sector() {
			const sectors = { EVT: 'event', DOM: 'residential', ORG: 'organization' };
			return sectors[this.report.type];
		},
	},
	methods: {
		...mapMutations(['clearReport']),
		reset(type) { this.clearReport(type); },
	},
};
</script>
