import i18n from '@/i18n';
import { reduceEmissions, longDate, toTones } from '@/utils';

// Import base64 images
import LogoGovern from '@/assets/img/LogoGovern';
import StampVLD from '@/assets/img/StampVLD';
import StampCMP from '@/assets/img/StampCMP';

/* eslint-disable camelcase */
export default function createLayout({ name, status, author, content, compensation, updated_at }) {
	const { details, emissions } = content;
	const summary = reduceEmissions(emissions);
	const generated = toTones(summary.reduce((acc, { total }) => acc + total, 0));
	const compensated = compensation ? compensation.amount : 0;

	return {
		pageMargins: [50, 100],
		background(currentPage, pageSize) {
			return [
				{
					canvas: currentPage === 1 ? [{
						type: 'rect',
						x: 0,
						y: 0,
						w: pageSize.width / 3.5,
						h: pageSize.height,
						color: '#174a7c',
					}] : [],
				},
				{
					image: 'LogoGovern',
					width: 120,
					margin: [23, -pageSize.height + 45, 0, 0],
				},
			];
		},
		header(currentPage) {
			return [
				{
					text: currentPage !== 1 ? name : '',
					margin: [50, 50, 0, 0],
					color: '#a0a0a0',
				},
				{
					text: currentPage !== 1 ? 'Emissions de CO2 calculades i compensades' : '',
					margin: [50, 0, 0, 0],
					color: '#a0a0a0',
				},
			];
		},
		footer(currentPage, pageCount) {
			return [
				{
					columns: [
						{
							text: currentPage !== 1 ? "Govern d'Andorra" : '',
							alignment: 'left',
							margin: [50, 45, 0, 0],
							color: '#a0a0a0',
						},
						{
							text: currentPage !== 1 ? `Pàgina ${currentPage} de ${pageCount}` : '',
							alignment: 'right',
							margin: [0, 45, 50, 0],
							color: '#a0a0a0',
						},
					],
				},
			];
		},
		content: [
			{ canvas: [{ type: 'rect', x: 170, y: 0, w: 330, h: 5, color: '#cbceda' }] },
			{ text: `EMISSIONS DE GASOS D’EFECTE HIVERNACLE -${details.year}-`, style: 'h1' },
			{ image: `Stamp${status}`, height: 80, width: 270, margin: [200, 0, 0, 0] },
      { // Event emissions
				text: author.name,
				margin: [180, 50, 0, 3],
				fontSize: 12,
				lineHeight: 1,
				color: '#174a7c',
			},
			{ // Event compensation
				stack: [
					`HA GENERAT ${generated} tCO2`,
					`HA COMPENSAT ${compensated} tCO2`,
				],
				margin: [180, 3, 0, 45],
				fontSize: 12,
				lineHeight: 1.5,
				color: '#174a7c',
			},
			{ // Advisory
				stack: [
					'Càlcul simplificat de les emissions de gasos d’efecte d’hivernacle efectuat amb l’eina que el Govern d’Andorra posa a disposició dels usuaris, d’acord amb l’article 51.4 de la Llei 21/2018, del 13 de setembre, d’impuls de la transició energètica i del canvi climàtic.',
					' ',
					"El contingut i l'estructura d'aquest informe segueix la norma ISO 14064. Els resultats finals s'expressen en tones de CO2 equivalent.",
					' ',
					"El Govern d’Andorra declina tota responsabilitat pel que fa a la veracitat i la certificació de les dades introduïdes per l'usuari a l’eina de càlcul.",
				],
				margin: [180, 0, 0, 0],
				fontSize: 8,
				color: '#174a7c',
			},
			{ canvas: [{ type: 'rect', x: 170, y: 20, w: 330, h: 5, color: '#cbceda' }] },
			{ // Date
				text: longDate(updated_at),
				margin: [180, 10, 0, 0],
				color: '#174a7c',
				alignment: 'center',
			},
			{ text: 'DETALLS', style: 'h2', pageBreak: 'before' },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 490, y2: 0, lineColor: '#174a7c' }] },
			{ // Event description
				margin: [0, 10],
				columns: [
					{ // Details
						layout: 'noBorders',
						table: {
							widths: ['auto', '*'],
							body: [
								[{ text: 'Nom', style: 'bold' }, details.name],
								[{ text: 'Any', style: 'bold' }, details.year],
								[{ text: 'Parròquia', style: 'bold' }, details.location.label],
								[{ text: 'Tipus', style: 'bold' }, details.type.label],
								[{ text: 'Codi CESI', style: 'bold' }, details.cesi_code || '-'],
								[{ text: 'Superfície', style: 'bold' }, details.surface || '-'],
								[{ text: 'Residents', style: 'bold' }, details.residents || '-'],
							],
						},
					},
				],
			},
			details.observations || '',
			// Abast tables and totals
			...summary.map((step) => [
				{ text: i18n.tc(`residential.${step.name}.name`), style: 'h2' },
				{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 490, y2: 0, lineColor: '#174a7c' }] },
				i18n.tc(`residential.${step.name}.desc`),
				{
					layout: 'lightHorizontalLines',
					margin: [10, 10],
					table: {
						headerRows: 0,
						widths: ['*', 'auto'],
						body: [
							['APARTAT', 'EMISSIONS'],
							...step.emissions.map((table) => [
								i18n.tc(`residential.${step.name}.${table.name}.title`),
								{ text: `${table.total} kgCO2`, alignment: 'right' },
							]),
							['', { text: `${toTones(step.total)} tCO2`, alignment: 'right', bold: true }],
						],
					},
				},
			]),
		],
		// Styles
		defaultStyle: { fontSize: 11, color: '#333' },
		styles: {
			h1: {
				alignment: 'center',
				fontSize: 40,
				bold: true,
				color: '#174a7c',
				margin: [170, 40, 0, 30],
			},
			h2: { margin: [0, 30, 0, 0], fontSize: 13, color: '#174a7c' },
			bold: { bold: true, color: '#174a7c' },
		},
		// Images
		images: { LogoGovern, StampVLD, StampCMP },
	};
}
/* eslint-disable camelcase */
