export default {

	title: 'Domèstic',
	tagline: 'Calcula les emissions generades per la unitat familiar. Utilitza el CERTIFICAT DIGITAL PERSONAL',

	details: {
		name: 'Detalls',
		title: 'Detalls',
		placeholder: {
			name: 'Nom',
			year: 'Any',
			location: 'Parròquia',
			type: 'Tipus',
			surface: 'Superfície m²',
			residents: 'Persones',
			cesi_code: 'Codi CESI',
			observations: 'Observacions',
		},

		cesi_find_info: 'Més informació sobre com trobar el codi CESI del teu edifici en {0}.',
		cesi_find_link: 'aquest document',
	},

	a1_2: {
		name: 'Emissions directes i indirectes',
		title: 'Emissions directes i indirectes de CO₂',
		desc: "Emissions associades al consum de combustibles fòssils, al transport propi, i a l'ús d'electricitat i calor.",
	},

	a1: {
		name: 'Emissions directes',
		title: 'Emissions directes de CO₂',
		desc: 'Emissions associades al consum de combustibles fòssils i al transport propi.',
		fuels: {
			title: 'Combustibles fòssils',
			desc: 'Introduïu la quantitat dels diferents combustibles consumits, a excepció dels relacionats amb el transport.',
			example: 'p.ex. Calefacció',
		},
		mobils: {
			title: 'Mobilitat amb vehicles propis',
			desc: 'Introduïu la informació relativa als vehicles propis utilitzats. Si coneixeu el consum de combustible associat a cada vehicle, utilitzeu el mètode 1. Com a alternativa, podeu utilitzar el mètode 2 si únicament coneixeu els quilòmetres realitzats.',
		},
	},

	a2: {
		name: 'Emissions indirectes',
		title: 'Emissions indirectes de CO₂',
		desc: "Emissions derivades de l'ús d'electricitat i calor.",
		energy: {
			title: 'Electricitat i calor',
			desc: "Introduïu l'origen i la quantitat d'electricitat o calor utilitzada.",
			example: 'p.ex. Consum elèctric',
		},
	},

	a3: {
		name: 'Altres emissions',
		title: 'Altres emissions de CO₂',
		desc: "Emissions indirectes derivades dels residus generats, consum d'aigua i el transport realitzat amb vehicles que no són de la vostra propietat.",
		spectators: {
			title: 'Mobilitat amb vehicles aliens',
			desc: 'Introduïu el transport vinculat a oci, viatges o mobilitat realitzat amb vehicles que no són de la vostra propietat.',
			example: 'p.ex. Viatge a Hawaii',
		},
		diet: {
			title: 'Dieta',
			desc: "Introduïu la informació relativa a la dieta dels individus de la unitat familiar. La dieta vegana exclou els productes d'origen animal (carn, peix, ous, llet). La  dieta vegetariana exclou la carn i el peix però inclou derivats animals com la llet o els ous. La dieta pescatariana exclou la carn en favor del peix i inclou derivats animals. La dieta omnívora sense carn vermella exclou la carn vermella però inclou la resta de productes animals. La dieta omnívora no presenta cap tipus d'exclusió o restricció.",
			example: 'p.ex. Dieta avi',
		},
		waste: {
			title: 'Residus',
			desc: "Introduïu la quantitat i tipus de residus generats a la llar durant l'any.",
			example: 'p.ex. Escombraries gener',
		},
		water: {
			title: 'Aigua',
			desc: "Introduïu la quantitat d'aigua consumida a la llar durant l'any.",
			example: 'p.ex. Reg gespa de jardí',
		},
	},

	// Needed for StepProgress
	results: { name: 'Resultats' },

};
