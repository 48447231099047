import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfLayouts from '@/assets/pdf-layouts/index';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createPDF = (report) => new Promise((resolve, reject) => {
	try {
		pdfMake
			.createPdf(pdfLayouts[report.type](report))
			.download(`${report.name}.pdf`, () => resolve());
	} catch (e) { reject(e); }
});

export default createPDF;
