<template lang="html">
	<confirm-dialog
		v-if="allow.includes(currentStatus.prev)"
		:data-tooltip="$t(`report.action.${currentStatus.action}`)"
		:question="$t('dialog.report_downgrade')"
		:acceptance="$t(`report.action.${currentStatus.action}`)"
		:refusal="$t('global.button.cancel')"
		@accept="handleUpgrade(currentStatus.fn)">
		<i class="mdi mdi-arrow-down-bold-hexagon-outline icon-btn" />
	</confirm-dialog>
</template>

<script>
import api from '@/api';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

const DOWNGRADES = {
	DFT: { prev: null },
	VLD: { prev: 'DFT', action: 'downgrade', fn: 'downgradeReport' },
	CMP: { prev: 'VLD', action: 'downgrade', fn: 'deleteCompensation' },
};

export default {
	name: 'ReportDowngrader',
	components: { ConfirmDialog },
	props: {
		allow: { type: Array, default: () => [] },
		report: { type: Object, required: true },
	},
	computed: {
		currentStatus() {
			return DOWNGRADES[this.report.status];
		},
	},
	methods: {
		handleUpgrade(fn) { this[fn](); },
		downgradeReport() {
			api.put(`/reports/${this.report.id}`, { status: this.currentStatus.prev })
				.then(({ data }) => this.$emit('upgrade', data))
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		deleteCompensation() {
			api.delete(`/compensations/${this.report.compensation.id}`)
				.then(() => this.downgradeReport())
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
	},
};
</script>
